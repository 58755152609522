<template>
    <el-card class="detector-body">
        <!-- v-if="detectorData[item.key]" -->
        <el-col v-for="item in detectorText" :key="item.key" :span="item.span || 8" class="detector-col">
                   <div class="detector-text">
                      <span>{{ item.text }}</span>
                   </div>
                   <div class="detector-value">
                        <el-tag type="success" v-if="!item.state">{{ detectorData[item.key] }} </el-tag>
                        <el-popover
                            v-else
                            placement="bottom"
                            :title="item.popoverTitle"
                            width="200"
                            trigger="click"
                            @show="showPopover"
                            @hide="hidePopover(item.key)"
                            >
                            <el-input v-model="changeValue"/>
                            <el-tag type="success" slot="reference">{{ detectorData[item.key] }} 
                                <i class="el-icon-arrow-down"></i> 
                            </el-tag>
                        </el-popover>
                    </div>
         </el-col>

    </el-card>
</template>

<script>
import { changeDetector,getBatchState } from '@/api/equipmentList'
const detectorText = [
    {key:'temperature',text: '温度', state:0},
    {key:'airHumidity',text: '空气湿度', state:1,popoverTitle:'取值范围'},
    {key:'formaldehydeConcent',text: '甲醛浓度', state:0},
    {key:'thresholdTemp',text: '最高温度', state:1,popoverTitle:'最高温度'},
    {key:'minTemp',text: '最低温度', state:1,popoverTitle:'最低温度'},
    {key:'concentPM25',text: 'PM2.5浓度', state:0},
    {key:'airHumidityThreshold',text: '空气湿度阈值', state:0},
    {key:'concentPM25Threshold',text: 'PM2.5浓度阈值', state:1,popoverTitle:'pm2.5浓度阙值',span:10},
    {key:'formaldehydeConcentThreshold',text: '甲醛浓度阈值',state:1,popoverTitle:'甲醛浓度阙值'},
    // {key:'uptime',text: '数据更新时间', state:0,span:10},
    {key:'systemTime',text: '设备系统时间', state:0,span:10}
]

export default {
    props:{
        detectorData:{}
    },
    data(){
       return {
            detectorText,
            changeValue:'',
            id:'',
            timer:null,
       }
    },
    created(){
        this.id = this.detectorData.id
        const that = this 
        this.timer = setInterval(() => {
            setTimeout(() => { getBatchState(that.id).then(res => {
                console.log(111);
                this.$emit('FchangeDetectorData',res.data.result[0])
            })},0)
        },5000)
    },
    beforeDestroy(){
        clearInterval(this.timer)
        this.timer = null
    },
    methods:{
        showPopover(){
            this.changeValue = ''
        },
        hidePopover(key){
            // console.log(this.detectorData,{ [key]: this.changeValue});
            // return
            if(this.changeValue == ''){
                return
            }
            changeDetector(this.detectorData.sn,{[key]:this.changeValue}).then(res => {
                if(res.data.status == 200){
                    this.$message.success('修改成功稍后生效')
                    getBatchState(this.id).then(res => {
                        const data = res.data.result[0]
                        // 子传父
                        this.$emit('FchangeDetectorData',data)
                    }).catch(err =>{
                        if(err){
                            const msg = err.response
                            console.log(msg);
                            this.$message.error('参数错误')
                        }
                    })
                }
            })
        }   
    }
}
</script>

<style scoped lang="scss">
.detector-body{
    display: flex;
    // flex-wrap: wrap;
    // justify-content: flex-start;
    padding-right: 30px;
    .detector-col{
         display:flex;
         margin: 20px 0; 
    }
}
.detector-text{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex: 1;
    box-sizing: border-box;
    // margin-left: 30px;
    padding-left: 30px;
}
.detector-value{
    // display: flex;
    // justify-content: flex-start;
    // align-items: center;
    flex: 1;
    // margin-right: 30px;
    span{
        width:100%;
        text-align: center;
    }
    .el-icon-arrow-down{
        float: right;
        margin-top: 10px;
    }
}
</style>